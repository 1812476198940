import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from './UserContext';
import styles from './Auth.module.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const NINETY_DAYS = 90 * 24 * 60 * 60;

  const setCrossDomainCookie = (name, value) => {
    document.cookie = `${name}=${value}; path=/; domain=.trendynerds.top; max-age=${NINETY_DAYS}; secure; samesite=strict`;
  };

  const handleRequestCode = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch('/api/request-login-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });
      
      const data = await response.json();
      
      if (response.ok) {
        setIsCodeSent(true);
        toast.success('Code sent to your email!');
      } else {
        throw new Error(data.message || 'Account not found');
      }
    } catch (error) {
      console.error('Login error:', error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginSuccess = (data) => {
    console.log("Starting login success handling with data:", data);

    // First set localStorage
    try {
        localStorage.setItem('token', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);
        localStorage.setItem('user', JSON.stringify(data.user));
        console.log("Successfully set localStorage items:", {
            token: localStorage.getItem('token'),
            refreshToken: localStorage.getItem('refreshToken'),
            user: localStorage.getItem('user')
        });
    } catch (error) {
        console.error('Error setting localStorage:', error);
    }

    // Then set cookies
    try {
        setCrossDomainCookie('token', data.accessToken);
        setCrossDomainCookie('refreshToken', data.refreshToken);
        setCrossDomainCookie('user', JSON.stringify(data.user));
        console.log("Cookies after setting:", document.cookie);
    } catch (error) {
        console.error('Error setting cookies:', error);
    }

    // Update React context
    setUser(data.user);

    // Notify about auth state change
    try {
        console.log("Attempting to notify Framer about auth state change");
        window.parent.postMessage(
            { type: 'authStateChanged' },
            'https://trendynerds.top'
        );
        console.log("Successfully sent postMessage to Framer");
    } catch (error) {
        console.error('Error notifying parent window:', error);
    }

    // Show success message and redirect
    toast.success('Login successful!');
    
    // Add a small delay before redirect to ensure everything is set
    setTimeout(() => {
        console.log("Redirecting to /stats");
        window.location.href = '/stats';
    }, 500);
};

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch('/api/verify-login-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, code: verificationCode })
      });

      const data = await response.json();
      
      if (response.ok) {
        handleLoginSuccess(data);
      } else {
        throw new Error(data.message || 'Invalid code');
      }
    } catch (error) {
      console.error('Verification error:', error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.authContainer}>
      <h2 className={styles.authTitle}>Login</h2>
      {!isCodeSent ? (
        <form onSubmit={handleRequestCode} className={styles.authForm}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={styles.authInput}
          />
          <button type="submit" className={styles.authButton} disabled={isLoading}>
            {isLoading ? 'Sending...' : 'Send Code'}
          </button>
        </form>
      ) : (
        <form onSubmit={handleVerifyCode} className={styles.authForm}>
          <input
            type="text"
            placeholder="Enter code from email"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            required
            className={styles.authInput}
          />
          <button type="submit" className={styles.authButton} disabled={isLoading}>
            {isLoading ? 'Verifying...' : 'Login'}
          </button>
          <button 
            type="button" 
            onClick={() => setIsCodeSent(false)}
            className={`${styles.authButton} ${styles.navLink}`}
          >
            Back
          </button>
        </form>
      )}
    </div>
  );
};

export default Login;