import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const getToken = () => {
    // Try localStorage first
    let token = localStorage.getItem('token');
    let refreshToken = localStorage.getItem('refreshToken');
    
    console.log('Tokens from localStorage:', {
      hasToken: !!token,
      hasRefreshToken: !!refreshToken
    });
    
    // If not in localStorage, try cookies
    if (!token || !refreshToken) {
      const cookies = document.cookie.split('; ');
      console.log('All cookies:', cookies);
      
      // Find and decode the tokens
      const tokenCookie = cookies.find(row => row.startsWith('token='));
      const refreshTokenCookie = cookies.find(row => row.startsWith('refreshToken='));
      
      if (tokenCookie) {
        token = decodeURIComponent(tokenCookie.split('=')[1]);
      }
      if (refreshTokenCookie) {
        refreshToken = decodeURIComponent(refreshTokenCookie.split('=')[1]);
      }
      
      console.log('Tokens from cookies:', {
        hasToken: !!token,
        hasRefreshToken: !!refreshToken,
        tokenPreview: token ? `${token.substring(0, 10)}...` : null
      });
    }
    
    return { token, refreshToken };
  };

  const refreshUserToken = async (refreshToken) => {
    try {
      const refreshResponse = await fetch('https://app.trendynerds.top/token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: refreshToken }),
        credentials: 'include'
      });

      if (refreshResponse.ok) {
        const { accessToken } = await refreshResponse.json();
        return accessToken;
      }
    } catch (error) {
      console.error('Token refresh failed:', error);
    }
    return null;
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        console.log('Starting auth check in app.trendynerds.top');
        const { token, refreshToken } = getToken();
        console.log('Tokens found:', { 
          hasToken: !!token, 
          hasRefreshToken: !!refreshToken,
          tokenPreview: token ? `${token.substring(0, 10)}...` : null
        });
        
        if (!token || !refreshToken) {
          console.log('No tokens found, redirecting to trendynerds.top');
          setLoading(false);
          window.location.href = 'https://trendynerds.top';
          return;
        }
  
        console.log('Attempting to fetch user data from /api/user');
        let currentToken = token;
        let response = await fetch('https://app.trendynerds.top/api/user', {
          headers: {
            'Authorization': `Bearer ${currentToken}`,
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });

        console.log('User data response:', {
          status: response.status,
          ok: response.ok
        });

        // Try token refresh if initial request fails
        if (response.status === 403 && refreshToken) {
          console.log('Attempting token refresh');
          const newToken = await refreshUserToken(refreshToken);
          if (newToken) {
            console.log('Token refresh successful');
            currentToken = newToken;
            response = await fetch('https://app.trendynerds.top/api/user', {
              headers: {
                'Authorization': `Bearer ${currentToken}`,
                'Content-Type': 'application/json'
              },
              credentials: 'include'
            });
            
            console.log('User data response after refresh:', {
              status: response.status,
              ok: response.ok
            });
          }
        }

        if (response.ok) {
          const userData = await response.json();
          console.log('User data retrieved successfully');
          
          // Update both localStorage and cookies
          localStorage.setItem('user', JSON.stringify(userData));
          localStorage.setItem('token', currentToken);
          localStorage.setItem('refreshToken', refreshToken);
          
          // Set cookies with appropriate domain
          const expires = new Date(Date.now() + 7776000 * 1000).toUTCString();
          const cookieOptions = `path=/; domain=.trendynerds.top; expires=${expires}; secure; samesite=none`;
          
          document.cookie = `token=${currentToken}; ${cookieOptions}`;
          document.cookie = `user=${encodeURIComponent(JSON.stringify(userData))}; ${cookieOptions}`;
          document.cookie = `refreshToken=${refreshToken}; ${cookieOptions}`;
          
          setUser(userData);
        } else {
          throw new Error('Invalid token');
        }
      } catch (error) {
        console.error('Auth check failed:', error);
        // Clear everything
        localStorage.clear();
        const domain = '.trendynerds.top';
        document.cookie = `token=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=none`;
        document.cookie = `user=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=none`;
        document.cookie = `refreshToken=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=none`;
        
        setUser(null);
        window.location.href = 'https://trendynerds.top';
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const value = {
    user,
    setUser,
    logout: () => {
      localStorage.clear();
      const domain = '.trendynerds.top';
      document.cookie = `token=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=none`;
      document.cookie = `user=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=none`;
      document.cookie = `refreshToken=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=none`;
      setUser(null);
      window.location.href = 'https://trendynerds.top';
    }
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};