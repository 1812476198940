import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext';
import styles from './NavbarFramer.module.css';
import { toast } from 'react-toastify';

const NavbarFramer = () => {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); // Ensure session is initialized

    const isAdmin = user?.is_admin;

    // Utility to clear cookies
    const clearCookie = (name, domain) => {
        // Clear cookie with specific domain
        document.cookie = `${name}=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=none`;
        // Also clear cookie without domain specification (for localhost/IP access)
        document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=none`;
    };

    const handleLogout = () => {
        // Clear localStorage from both domains
        const clearLocalStorage = () => {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('tsb_uid');
            localStorage.removeItem('tsb_token');
            localStorage.removeItem('lastAuthUpdate');
        };

        // Clear localStorage in current domain
        clearLocalStorage();

        // Try to clear localStorage in parent domain
        try {
            window.parent.localStorage.clear();
        } catch (error) {
            console.warn('Could not directly clear parent localStorage:', error);
        }

        // Clear cookies for both domains
        const domains = ['.trendynerds.top', 'app.trendynerds.top'];
        const cookiesToClear = ['token', 'refreshToken', 'user', 'tsb_uid', 'tsb_token', 'lastAuthUpdate'];

        domains.forEach(domain => {
            cookiesToClear.forEach(cookieName => {
                // Clear cookie with specific domain
                document.cookie = `${cookieName}=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=none`;
                // Also clear cookie without domain specification
                document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=none`;
            });
        });

        // Update React context
        setUser(null);

        // Notify parent window about logout
        try {
            window.parent.postMessage(
                { 
                    type: 'authStateChanged',
                    status: 'loggedOut',
                    action: 'clearStorage'
                },
                'https://trendynerds.top'
            );
        } catch (error) {
            console.error('Error notifying parent window:', error);
        }

        // Show success message
        toast.success('Successfully logged out!');

        // Redirect to main site
        window.location.href = 'https://trendynerds.top';
    };

    const handleBack = () => {
        window.location.href = 'https://trendynerds.top';
    };

    // Wait for session initialization
    useEffect(() => {
        const initializeSession = () => {
            if (user !== null) {
                setLoading(false); // Session is initialized
            }
        };
        initializeSession();
    }, [user]);

    if (loading) {
        // Show nothing while waiting for session initialization
        return null;
    }

    if (!user) {
        // Avoid abrupt redirect during initialization
        navigate('https://trendynerds.top');
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <nav className={styles.nav}>
                <div className={styles.navCenter}>
                    <Link 
                        to="/"
                        onClick={(e) => {
                            e.preventDefault();
                            handleBack();
                        }}
                        className={styles.navLink}
                    >
                        &lt; back
                    </Link>
                    {isAdmin && (
                        <Link 
                            to="/review"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/review');
                            }}
                            className={styles.navLink}
                        >
                            review
                        </Link>
                    )}
                    {isAdmin && (
                        <Link 
                            to="/"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/');
                            }}
                            className={styles.navLink}
                        >
                            dashboard
                        </Link>
                    )}
                    <Link 
                        to="/"
                        onClick={(e) => {
                            e.preventDefault();
                            handleLogout();
                        }}
                        className={styles.navLink}
                    >
                        log out
                    </Link>
                </div>
            </nav>
            <div className={styles.separator}></div>
        </div>
    );
};

export default NavbarFramer;
